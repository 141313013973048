//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [clickaway],
  props: {
    contentClass: String,
  },
  data () {
    return {
      opened: false,
      width: '400px',
      top: '200px',
    }
  },
  computed: {
    windowPosition () {
      return {
        right: 0,
        width: this.width,
        top: this.top,
      }
    },
  },
  watch: {
    opened: {
      handler (opened) {
        if (opened) {
          this.fixPos()
        } else {
          this.observer && this.observer.disconnect()
          this.observer = null
        }
      },
      immediate: true,
    },
  },
  methods: {
    close () {
      this.opened = false
    },
    fixPos () {
      const heading = this.$parent.$refs.heading
      if (!heading) return
      const rect = heading.getBoundingClientRect()

      this.width = rect.width + 'px'
      this.top = (rect.height + 6) + 'px'

      if (!this.observer && this.opened && window.MutationObserver) {
        this.observer = new window.MutationObserver(() => {
          this.fixPos()
        })

        this.observer.observe(this.$parent.$refs.heading, { attributes: true, childList: true, subtree: true })
      }
    },
  },
}
